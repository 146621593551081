/** @jsx jsx */
import { jsx, Box } from 'theme-ui';
import { useTranslation, Trans } from 'react-i18next';
import SEO from '../components/seo';
import { Connection, ContentFrontmatter, ContentNode, Edge, PageRoot } from '../data/content';
import { Fragment, useEffect, useState } from "react";
import { useTextDirection } from '../i18n';
import { Link } from '../components/nav';
import { ContributeAction } from '../components/actions';
import { CustomSearchState, SearchComponent, searchFields } from '../components/search';
import { ContentBlock, HeaderSpacing } from '../components/page';
import qs from 'query-string';
import * as yup from 'yup';
import { graphql } from 'gatsby';

const fields = {
  ...searchFields
}

const SearchPage: PageRoot<{
  previews: Connection<ContentNode>
}> = ({ data: { previews }, location, location: { pathname, search } }) => {
  const { i18n: { language }, t } = useTranslation()
  const dir = useTextDirection(language)

  const state = location.state as CustomSearchState

  const schema = yup.object({ ...fields })

  const [searchTerms, setSearchTerms] = useState<string>("");

  useEffect(() => {
    const queryParams = qs.parse(typeof window === 'undefined' ? '' : window.location.search);

    const defaultValues: yup.InferType<typeof schema> = schema.cast({
      ...queryParams
    })

    if (defaultValues.SEARCH_TERM && searchTerms != defaultValues.SEARCH_TERM) {
      setSearchTerms(defaultValues.SEARCH_TERM);
    }
  }, [search])

  return (
    <Fragment>
      <ContentBlock>
        <HeaderSpacing />
        <Box>
          <SEO
            path={pathname}
            title={'Search'}
          />
          <SearchComponent previews={previews} searchTerms={searchTerms} state={state} setSearchTerms={setSearchTerms} langInfo={{ language, t, dir }} />
        </Box>
      </ContentBlock>
    </Fragment>
  )
}

export const pageQuery = graphql`
query SearchPage {
  previews: allMarkdownRemark(
    filter: { fields: { type: { in: ["wire-articles", "blueprint-documents", "movement-campaigns", "observatory-articles", "movement-actions"] } } }
  ) {
    edges {
      node {
        ...SearchListFragment
      }
    }
  }
}
`

export default SearchPage